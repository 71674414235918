import React, { lazy } from 'react';
import { hasAccess } from 'utils/permissionHelper';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SignIn = lazy(() => import('./../Auth/routes/SignIn'));
const SignUp = lazy(() => import('./../Auth/routes/SignUp'));
const ResetPassword = lazy(() => import('./../Auth/routes/ResetPassword'));
const ForgotPassword = lazy(() => import('./../Auth/routes/ForgotPassword'));
const SubscribeView = lazy(() => import('./../Dash/routes/SubscribeView'));
const BillingView = lazy(() => import('./../Dash/routes/BillingView'));
const BillingCardUpdate = lazy(() => import('./../Dash/routes/BillingCardUpdate'));
const RentView = lazy(() => import('./../Dash/routes/RentView'));
const SubscribePlansView = lazy(() => import('./../Dash/routes/SubscribePlansView'));

const Modals = () => {
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page');
  const isAuth = useSelector(state => state.isAuthenticated);
  const isGuest = hasAccess('guest_user');

  const hideAuthPage = isAuth && !isGuest;

  const modals = {
    signin: { component: <SignIn />, hide: hideAuthPage },
    signup: { component: <SignUp />, hide: hideAuthPage },
    reset: { component: <ResetPassword />, hide: hideAuthPage },
    forgot: { component: <ForgotPassword />, hide: hideAuthPage },
    subscribe: { component: <SubscribeView />, hide: !hideAuthPage },
    plans: { component: <SubscribePlansView />, hide: !hideAuthPage },
    subscription: { component: <SubscribePlansView />, hide: !hideAuthPage },
    rent: { component: <RentView />, hide: !hideAuthPage },
    billing: { component: <BillingView />, hide: !hideAuthPage },
    card: { component: <BillingCardUpdate />, hide: !hideAuthPage },
  };

  const activeModal = modals[page];

  return activeModal && !activeModal?.hide && activeModal.component;
};

export default Modals;
